import React, { useState, useEffect } from 'react';
import StatusTable from './StatusTable';
import DeltaDetails from './DeltaDetails';
import { aiStatus, stagingStatus } from './apiStatusCall';

function DeltaInfo() {
    const [vendor, setVendor] = useState("EWII");
    const [initAI, setInitAI] = useState(0);
    const [initStaging, setInitStaging] = useState(0);
    const [initTime, setInitTime] = useState(new Date().toLocaleTimeString());
    const [intervalTime, setIntervalTime] = useState("off");

    useEffect(() => {
        setInitAI(0);
        setInitStaging(0);
        setInitTime(new Date().toLocaleTimeString());
        
        aiStatus(vendor).then(res => setInitAI(res));
        stagingStatus(vendor).then(res => setInitStaging(res));
        setInitTime(new Date().toLocaleTimeString());
        setIntervalTime("off")
        //eslint-disable-next-line
    }, [vendor])
    return (
        <div className="card">
            <div className="card-header">
                <div className="row">
                    <div className="col-sm-8">
                        <h5 >Delta Info</h5>
                    </div>
                    <div className="col-sm-4">
                        <select className="form-control form-control-sm" id="vendor"
                            value={vendor} onChange={(e) => setVendor(e.target.value)}>
                            <option value="EWII" defaultChecked>Ewii-whitelist</option>
                            <option value="FIBER" defaultChecked>TDC-fiber-FiberAddressList</option>
                            <option value="COPPER" defaultChecked>TDC-copper-copperAddressList</option>
                            <option value="MOBILE" defaultChecked>TDC-mobile-mobileAddressList</option>
                            <option value="OPENNET" defaultChecked>Opennet-SP01</option>
                            <option value="FIBIA" defaultChecked>Fibia-ADDRESS</option>
                            <option value="COAX" defaultChecked>TDC-COAX-CoaxAddressList_Nuuday</option>
                        </select>
                    </div>

                </div>
            </div>

            <div className="card-body">
                <StatusTable title={vendor} initAI={initAI} initStaging={initStaging} initTime={initTime} intervalTime={intervalTime} setIntervalTime={setIntervalTime} />
                <DeltaDetails title={vendor} intervalTime={intervalTime} />
            </div>
        </div>
    );
}

export default DeltaInfo;