import React from 'react';
import { vendors } from '../../../data/constant';

function AiModal({ aiData, sData }) {
    return (
      <table className="table table-striped" style={{ textAlign: "left" }}>
        <thead className="thead-light">
          <tr>
            <th>Access Inventory Data</th>
            <th>{vendors.find(v => v.value === aiData.vendorId) ? vendors.find(v => v.value === aiData.vendorId).name : null} Staging Details</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
               {Object.keys(aiData).map((k, i) =>
                 <div className="div-table" key={i}>
                   <div style={{ position: 'relative', overflowX: 'auto', textAlign: 'left' }}>{k} : {aiData[k].toString()}</div>                 </div>
               )}
            </td>
            <td>
               {sData && Object.keys(sData).map((k, i) =>
                 <div className="div-table" key={i}>
                   <div style={{ position: 'relative', overflowX: 'auto', textAlign: 'left' }}>{k} : {sData[k].toString()}</div>              </div>
               )}
            </td>
          </tr>
        </tbody>
      </table>
    );
}

export default React.memo(AiModal);