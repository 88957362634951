import React from 'react';

export default function ReleaseInfo() {
  return (
    <div className="release">
      <h6>Please click on the text to get details on release info:</h6>
      <li>
      <a href="https://wiki.nuuday.dk/spaces/NVSD/pages/338380852/5.3+Releasing" target="_blank" class="link">Release Info</a>
      </li>
    </div>
  )
}
