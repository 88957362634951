import axios from 'axios';
import {getToken} from './outhApi';

const AuthAxios = axios.create({
    baseURL: process.env.REACT_APP_BASEURL,
    headers: { 'X-Requested-With': 'XMLHttpRequest', 'Content-Type': 'application/json', 'Access-Control-Allow-Origin' : '*' },
})

AuthAxios.interceptors.request.use(req => {
    // console.log(`token ${getToken()}`);
    req.headers.authorization = `Bearer ${getToken()}`;
    return req;
});

AuthAxios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    console.log(error);
    if (error.response && error.response.status) {
        error = {...error, message: errorMsg[error.response.status]};
    } else {
        if (error.message.includes('Network Error')) {
            return Promise.reject(error);
        } else {
            error = {...error, message: "Something went wrong."};
        }
    }
    return Promise.reject(error);
});

export default AuthAxios;

const errorMsg = {
    400: "Bad Request",
    401: "Unauthorized. Please refresh the page to renew token or contact to administrator for access.",
    403: "Forbidden. Please contact to administrator for access.",
    404: "URL Not Found",
    500: "Internal Server Error",
    501: "Not Implemented",
    502: "Bad Gateway",
    503: "Service Unavailable",
    504: "Request Timeout. Please retry with minimum records per page."
}

export const catalogTech = ['H1', 'GPON', 'P2P'];

export const hasAccess = () => {
    if (getToken()) {
        let roles = JSON.parse(atob(getToken().split(".")[1])).roles;
        if (roles) {
            if (typeof roles === "string") {
                return roles.includes("OPERATIONAL");
            } else {
                return roles.find(role => role.includes("OPERATIONAL")) ? true : false;
            }
        } else {
            return false;
        }

    }
}

export const hasAdminWriteAccess = (token) => {
    if (token) {
        let roles = JSON.parse(window.atob(token.split(".")[1])).roles;
        if (roles) {
            if (typeof roles === "string") {
                return roles.includes("Admin") || roles.includes("Write");
            } else {
                return roles.find(role => role.includes("Admin") || role.includes("Write")) ? true : false;
            }
        } else {
            return false;
        }

    } else {
        return false;
    }
}

export const hasInternalAccess = () => {
    if (getToken()) {
        let roles = JSON.parse(atob(getToken().split(".")[1])).roles;
        if (roles) {
            if (typeof roles === "string") {
                return roles.includes("INTERNAL");
            } else {
                return roles.find(role => role.includes("INTERNAL")) ? true : false;
            }
        } else {
            return false;
        }

    }
}

export const checkInternalAccess = (token) => {
  let roles = JSON.parse(atob(token.split(".")[1])).roles;
  if (roles) {
      if (typeof roles === "string") {
          return roles.includes("INTERNAL");
      } else {
          return roles.find(role => role.includes("INTERNAL")) ? true : false;
      }
  } else {
      return false;
  }
}

export const checkOperatorAccess = (token) => {
  let roles = JSON.parse(atob(token.split(".")[1])).roles;
  console.log(roles);
  if (roles) {
      if (typeof roles === "string") {
          return roles.includes("OPERATOR");
      } else {
          return roles.find(role => role.includes("OPERATOR")) ? true : false;
      }
  } else {
      return false;
  }
}