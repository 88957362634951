import React, { useState } from 'react';
import { vendors } from '../../../data/constant';
import axios from '../../../api/management_api';
import AiModal from './AiModal';
import Modal from '../../common/modal/Modal';
import ErrorBody from '../../common/error/ErrorBody';

export default function AiTable({ rowData, migrateToVendorId }) {
    const [initialRowData, setInitialRowData] = useState(
      rowData
    );
    const [select, setSelect] = useState();
    const [modal, setModal] = useState({ title: 'title', data: '' });
    const [info, setInfo] = useState();
    const [stagingData, setStagingData] = useState();
    const [error, setError] = useState();
    const setDetails = (data) => {
       setInfo("");
       setError("");
       setModal({ title: `Details`, data: '' });
       if (data) {
         setInfo(data);
         setStagingDataForAI(data.vendorId, data.stagingId, data.technology);
       } else {
         setError("No data found");
       }
    }

    const setStagingDataForAI = (vendorId, stagingId, technology) => {
        const vendor = vendors.find(v => v.value === vendorId) ? vendors.find(v => v.value === vendorId).name : null;
        axios.get(`/v2/whiteListStaging/fetchStagingData?vendor=${vendor}&stagingId=${stagingId}&technology=${technology}`)
        .then(response => {
          setStagingData(response.data);
        }).catch(err => setError(err.message))
    }
    const flipAiSelectedData = (installationId, id) => {
        console.log(migrateToVendorId);
        const vendor = vendors.find(v => v.value == migrateToVendorId) ? vendors.find(v => v.value == migrateToVendorId).name : null;
        axios.get(`/v2/accessinventory/flipAiData?installationId=${installationId}&toVendorId=${migrateToVendorId}`)
        .then(response => {
         setInitialRowData(prevValue =>
              [...prevValue].map(el =>
                  el.id === id ? ({...el,
                  installationId:response.data.installationId,
                  madId:response.data.madId,
                  externalInstallationId:response.data.externalInstallationId,
                  namId:response.data.namId,
                  vendorAddressId:response.data.vendorAddressId,
                  available:response.data.available,
                  vendorId:response.data.vendorId,
                  infrastructureOwner:response.data.infrastructureOwner,
                  installationCode:response.data.installationCode,
                  technicianNeeded:response.data.technicianNeeded,
                  changedDate:response.data.changedDate,
                  leadTime:response.data.leadTime,
                  technology:response.data.technology,
                  lineSpeedUp:response.data.lineSpeedUp,
                  lineSpeedDown:response.data.lineSpeedDown,
                  establishmentType:response.data.establishmentType
                  }) : el)
              )
        }).catch(err => {
          setError(err.message)
        });
    }
    return (
        <div style={{ position: 'relative', overflow: 'scroll', textAlign: 'center', maxHeight: "70vh" }}>
            <button id="direct-Search" data-toggle="modal" data-target='#tran-modal' hidden></button>
                    <Modal id="tran-modal" title={modal.title}
                      res={info ? <AiModal aiData={info} sData={stagingData}/> : ''}
                      error={error ? <ErrorBody errorMessage={error} /> : ''} />
            <table className="table table-sm table-striped table-bordered">
                <thead className="thead-light">
                    <tr>
                        <th>Installation id</th>
                        <th>Address id</th>
                        <th>External installation id</th>
                        <th>Nam id</th>
                        <th>Vendr address id</th>
                        <th>Available</th>
                        <th>Vendor</th>
                        <th>Infrastructure owner</th>
                        <th>Installation code</th>
                        <th>Technician needed</th>
                        <th>Changed date</th>
                        <th>Lead time</th>
                        <th>Technology</th>
                        <th>Speed up</th>
                        <th>Speed down</th>
                        <th>Establishment Type</th>
                    </tr>
                </thead>
                <tbody>
                    {initialRowData.map((r, index) => <tr key={index} className={r.id === select ? "select-row" : ""}
                        onClick={() => setSelect(r.id)}>
                        <td>
                            <input
                              type="radio"
                              id="aiFlipRadio"
                              name="accessInventory-flip-type"
                              className=""
                              onChange={() => {flipAiSelectedData(r.installationId, r.id)}} />&nbsp;
                            {r.installationId} &nbsp;
                            <button className="btn btn-sm btn-secondary"
                              data-toggle="modal" data-target='#tran-modal'
                              onClick={() => setDetails(r)}>
                              view
                            </button>
                        </td>
                        <td>{r.madId}</td>
                        <td>{r.externalInstallationId}</td>
                        <td>{r.namId}</td>
                        <td>{r.vendorAddressId}</td>
                        <td>{r.available ? "Yes" : "No"}</td>
                        <td>{vendors.find(v => v.value === r.vendorId) ? vendors.find(v => v.value === r.vendorId).name : null}</td>
                        <td>{r.infrastructureOwner}</td>
                        <td>{r.installationCode}</td>
                        <td>{r.technicianNeeded}</td>
                        <td>{r.changedDate ? r.changedDate.split("T")[0] : null}</td>
                        <td>{r.leadTime}</td>
                        <td>{r.technology}</td>
                        <td>{r.lineSpeedUp}</td>
                        <td>{r.lineSpeedDown}</td>
                        <td>{r.establishmentType}</td>
                    </tr>)}
                </tbody>
            </table>
        </div>
    )
}
