import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchangeAlt, faRetweet, faChartLine, faSearch, faBug, faAlignLeft, faFileShield, faCogs, faSignOutAlt, faBriefcase, faLayerGroup, faNetworkWired, faCubes, faCog, faClone, faArchive, faTable, faAddressBook, faBolt, faSliders } from '@fortawesome/free-solid-svg-icons';
import '../../../asset/css/nav.css';
import { hasAccess, checkInternalAccess, checkOperatorAccess } from '../../../api/management_api';
import { version } from '../../../data/constant';
import { useMsal } from "@azure/msal-react";
import {loginRequest} from "../../../api/msalconfig";


function Nav(props) {
  const [toggled, setToggled] = useState('');
  const access = useState(hasAccess())[0];
  const [internalAccess, setInternalAccess] = useState(false);
  const [operatorAccess, setOperatorAccess] = useState(false);
  const { instance, accounts } = useMsal();

  useEffect(() => {
    if (accounts.length > 0) {
      instance.acquireTokenSilent({ ...loginRequest, account: accounts[0] }).then((response) => {
        setInternalAccess(checkInternalAccess(response.accessToken));
        setOperatorAccess(checkOperatorAccess(response.accessToken));
        sessionStorage.setItem("accessToken", response.accessToken);
      }).catch((e) => {
        instance.acquireTokenPopup({ ...loginRequest, account: accounts[0] }).then((response) => {
          setInternalAccess(checkInternalAccess(response.accessToken));
          setOperatorAccess(checkOperatorAccess(response.accessToken));
          sessionStorage.setItem("accessToken", response.accessToken);
        });
      });
    }
  }, [accounts, instance])

  const logoutOuth = async () => {
    await sessionStorage.clear();
    await instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  }
  return (
    <div className="wrapper bg1" >
      <nav id="sidebar" className={toggled}>
        <div className="sidebar-header">
          <NavLink to='/home'><h2>Dashboard</h2></NavLink>
          <strong>VSA</strong>
        </div>

        <ul className="list-unstyled components">
          <div>
            <li className="active">
              <a href="#monitor" data-toggle="collapse" aria-expanded="true" className="dropdown-toggle">
                <span><FontAwesomeIcon className="mr-1" icon={faChartLine} /></span>Monitor</a>
              <ul className="collapse list-unstyled show" id="monitor">
                <li>
                  <NavLink to='/monitor/catalog' className={({ isActive }) =>
                    isActive ? "navLink-active" : undefined
                  }>
                    <span><FontAwesomeIcon className="mr-1" icon={faLayerGroup} /></span>Catalog</NavLink>
                </li>
                <li>
                  <NavLink to='/monitor/serviceCatalog' className={({ isActive }) =>
                    isActive ? "navLink-active" : undefined
                  }>
                    <span><FontAwesomeIcon className="mr-1" icon={faLayerGroup} /></span>Service Catalog</NavLink>
                </li>
                <li>
                  <NavLink to='/monitor/speed' className={({ isActive }) =>
                    isActive ? "navLink-active" : undefined
                  }>
                    <span><FontAwesomeIcon className="mr-1" icon={faBolt} /></span>Max speed</NavLink>
                </li>
                <li>
                  <NavLink to='/monitor/history' className={({ isActive }) =>
                    isActive ? "navLink-active" : undefined
                  }>
                    <span><FontAwesomeIcon className="mr-1" icon={faSearch} /></span>History</NavLink>
                </li>
                <li>
                  <NavLink to='/monitor/ai' className={({ isActive }) =>
                    isActive ? "navLink-active" : undefined
                  }>
                    <span><FontAwesomeIcon className="mr-1" icon={faTable} /></span>Access Inventory</NavLink>
                </li>
                 <li>
                   <NavLink to='/monitor/ai-flip' className={({ isActive }) =>
                    isActive ? "navLink-active" : undefined
                   }>
                     <span><FontAwesomeIcon className="mr-1" icon={faTable} /></span>Access Inventory Flip</NavLink>
                </li>
                <li>
                  <NavLink to='/monitor/staging' className={({ isActive }) =>
                    isActive ? "navLink-active" : undefined
                  }>
                    <span><FontAwesomeIcon className="mr-1" icon={faAddressBook} /></span>Staging Inventory</NavLink>
                </li>
              </ul>
            </li>
            <li>
            </li>
          </div>
          <div> { operatorAccess ?
            <li className="active">
              <a href="#management" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">
                <span><FontAwesomeIcon className="mr-1" icon={faBriefcase} /></span>Management</a>
              <ul className="collapse list-unstyled" id="management">
                <li>
                  <NavLink to='/management/property' className={({ isActive }) =>
                    isActive ? "navLink-active" : undefined
                  }>
                    <span><FontAwesomeIcon className="mr-1" icon={faSliders} /></span>Property</NavLink>
                </li>
                <li>
                  <NavLink to='/management/env' className={({ isActive }) =>
                    isActive ? "navLink-active" : undefined
                  }>
                    <span><FontAwesomeIcon className="mr-1" icon={faFileShield} /></span>Env</NavLink>
                </li>
                <li>
                  <NavLink to='/management/cache' className={({ isActive }) =>
                    isActive ? "navLink-active" : undefined
                  }>
                    <span><FontAwesomeIcon className="mr-1" icon={faArchive} /></span>Cache</NavLink>
                </li>

              </ul>
            </li> : null}
            <li>
            </li>
          </div>
          <div>
            <li className="active">
              <a href="#test" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">
                <span><FontAwesomeIcon className="mr-1" icon={faBug} /></span>Test</a>
              <ul className="collapse list-unstyled" id="test">
                <li>
                  <NavLink to='/test/service' className={({ isActive }) =>
                    isActive ? "navLink-active" : undefined
                  }>
                    <span><FontAwesomeIcon className="mr-1" icon={faCogs} /></span>Service</NavLink>
                </li>
                <li>
                  <NavLink to="/visitation-swagger" className={({ isActive }) => (isActive ? 'selected' : '')}>
                    <span><FontAwesomeIcon icon={faCogs} className="mr-1" /></span>
                    Swagger
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
            </li>
          </div>
          {internalAccess ? <div>
              <li className="active">
                <a href="#transaction" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">
                    <span><FontAwesomeIcon className="mr-1" icon={faExchangeAlt} /></span>Transaction</a>
                <ul className="collapse list-unstyled" id="transaction">
                  <li>
                    <NavLink exact to='/transaction/details' className={({ isActive }) => isActive ? "navLink-active" : undefined}>
                      <FontAwesomeIcon className="mr-1" icon={faTable} />Show Details</NavLink>
                  </li>
                  <li>
                    <NavLink exact to='/transaction/delta' className={({ isActive }) => isActive ? "navLink-active" : undefined}>
                      <FontAwesomeIcon className="mr-1" icon={faLayerGroup} />Delta Info</NavLink>
                  </li>
                  <li>
                    <NavLink exact to='/transaction/retry' className={({ isActive }) => isActive ? "navLink-active" : undefined}>
                      <FontAwesomeIcon className="mr-1" icon={faRetweet} />Retry</NavLink>
                  </li>
                </ul>
              </li>
          </div> : null}
        </ul>
        <ul className="list-unstyled CTAs">
          <li onClick={() => logoutOuth()}>
            <NavLink to='/' className="article"><FontAwesomeIcon icon={faSignOutAlt} /><span>Log out</span></NavLink>
          </li>
        </ul>
      </nav>

      <div id="content">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid">

            <button type="button" id="sidebarCollapse" className="btn btn-info" onClick={() => toggled === '' ? setToggled("active") : setToggled('')}>
              <FontAwesomeIcon className="mr-1" icon={faAlignLeft} />
            </button>
            <div className="logo-image"></div>

            <ul className="nav navbar-nav ml-auto" >
              <li className="nav-item active">
                <NavLink to='/home'><h2> Visitation Service Admin<i className="h6">{version}</i></h2></NavLink>
              </li>
            </ul>

          </div>

        </nav>
        <div >
          <div className="row">
            <div className="col-12">
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


export default React.memo(Nav);